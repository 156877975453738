<template>
  <a-modal
    :maskClosable="false"
    class="product-form"
    :title="handle == 'add' ? '新增' : handle == 'edit' ? '编辑' : '查看'"
    style="top: 8px"
    :width="1200"
    v-model="visible"
    
    :footer="null"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      labelAlign="right"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <div class="form-title">商品基础信息</div>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="商品名称" prop="name">
            <a-input
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              v-model="rowData.name"
              placeholder="请输入"
            ></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="商品分类" class="required_fields" :autoLink="false" ref="test" prop="categoryIds">
            <a-cascader
              v-model="categoryIds"
              :field-names="{ label: 'name', value: 'id', children: 'children' }"
              :options="categoryList"
              placeholder="请选择"
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              @change="onChange"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="商品品牌" prop="brandId" class="required_fields" :autoLink="false" ref="test">
            <a-select
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              placeholder="请选择"
              v-model="brandId"
              label-in-value
              @change="onBrandChange"
            >
              <a-select-option :value="item.id" v-for="(item, index) in brandList" :key="index">{{
                item.chName
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
<!--        <a-col :md="12">
          <a-form-model-item label="排序" prop="">
            <a-input
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              v-model="sort"
              placeholder="请输入"
            ></a-input>
          </a-form-model-item>
        </a-col>-->
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="建议零售价" prop="price">
            <a-input-number
              :formatter="(value) => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
              :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              v-model="rowData.price"
              placeholder="请输入"
              style="width: 100%"
            ></a-input-number>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="库存" class="required_fields required_fields1" prop="stock">
            <a-input
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              v-model="rowData.stock"
              placeholder="请输入"
            ></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="单位" prop="proUnit" :autoLink="false" ref="test">
            <a-select v-model="rowData.proUnit" placeholder="请选择" @change="onProUnitChange">
              <a-select-option :value="item.id" v-for="(item, index) in nuits" :key="index">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="关键词" prop="proKeywords">
            <a-input
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              v-model="rowData.proKeywords"
              placeholder="请输入"
            ></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="供应商" prop="minQuantity" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-select mode="multiple" v-model="rowData.channelIds" placeholder="请选择">
              <a-select-option :value="item.id" v-for="(item, index) in ditchList" :key="index">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <!-- <a-col :md="12">
          <a-form-model-item label="功能类型" prop="ids">
            <a-select
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              placeholder="请选择"
              mode="multiple"
              v-model="rowData.ids">
              <a-select-option :value="item.id" v-for="(item, index) in functionType" :key="index">{{item.name}}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col> -->
      </a-row>
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="商品主图" class="buju required_fields" prop="specStr">
            <QiniuUpload
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              type="image"
              :value.sync="rowData.imgUrl"
            ></QiniuUpload>
            <div class="upload-hint-text">支持扩展名：.jpg .png</div>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="商品图片" class="buju">
            <QiniuUpload
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              type="image"
              :value.sync="url"
              :size="5"
            ></QiniuUpload>
            <div class="upload-hint-text">支持扩展名：.jpg .png</div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <!-- <a-row>
        <a-col :md="12">
          <a-form-model-item label="状态" prop="status">
            <a-switch
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              v-model="rowData.status"
              checked-children="启用"
              un-checked-children="禁用"
              default-checked
            />
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="置顶" prop="flagTop">
            <a-switch
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              v-model="rowData.flagTop"
              checked-children="启用"
              un-checked-children="禁用"
              default-checked
            />
          </a-form-model-item>
        </a-col>
      </a-row> -->
      <a-row>
        <a-col :md="12">
          <a-form-model-item label="审核后上架">
            <a-switch
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              v-model="flagAutoUp"
              checked-children="启用"
              un-checked-children="禁用"
              default-checked
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="24">
          <a-form-model-item label="商品描述" prop="proIntro" :labelCol="{ span: 3 }" :wrapperCol="{ span: 21 }">
            <a-textarea
              :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
              v-model="rowData.proIntro"
              placeholder="请输入"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <div class="form-title" v-if="propertyList.length > 0">商品属性</div>
      <a-row>
        <a-col :span="12" v-for="(item, index) in propertyList" :key="index">
          <a-form-model-item v-if="item.type == 1" :label="item.name">
            <a-select v-model="item.valueObj" label-in-value @change="onchange">
              <a-select-option :value="data.id" v-for="(data, index) in item.propertyValueList" :key="index">{{
                data.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="item.type == 0" :label="item.name">
            <a-input v-for="(data, index) in item.propertyValueList" :key="index" v-model="data.name"></a-input>
          </a-form-model-item>
          <!-- <a-form-model-item
            v-if="item.type == 2"
            v-for="(item, index) in propertyList"
            :key="index"
            :label="item.name"
          >
            <a-select v-model="item.valueObj" label-in-value @change="onchange" mode="multiple">
              <a-select-option :value="data.id" v-for="(data, index) in item.propertyValueList" :key="index">{{
                data.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item> -->
        </a-col>
      </a-row>

      <div class="form-title" v-if="typeSpecList.length">商品规格</div>
      <div class="for-itemlis">
        <div class="item-tabbox">
          <!--规格设置1-->
          <div class="ditor-01" v-if="typeSpecList.length">
            <a-table :data-source="typeSpecList" bordered :pagination="false">
              <a-table-column title="规格名称" key="name" data-index="name">
                <template slot-scope="name">
                  {{ name }}
                </template>
              </a-table-column>
              <a-table-column title="规格值 (选中规格值生成商品SKU)" key="">
                <template slot-scope="scope, record">
                  <ul>
                    <li
                      :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
                      class="typ_lis"
                      v-for="(item, index) in record.specValueList"
                      @click="typeSpecfn($event, record.name, item.specId, item.name, item.id)"
                      :class="{
                        active:
                          typeSpecArr.length &&
                          typeSpecArr.indexOf(`${item.specId}卍${record.name}卍${item.name}`) >= 0,
                      }"
                      :key="index"
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </template>
              </a-table-column>
            </a-table>
          </div>
          <!--规格设置2-->
          <a-table
            class="singleTablecls"
            ref="singleTable"
            v-if="typeSpecTableData.length"
            :data-source="typeSpecTableData"
            bordered
            :key="loadingtable"
          >
            <a-table-column :width="50" key="index" data-index="index" fixed="left">
              <template slot-scope="scope, record, index">{{ index + 1 }}</template>
            </a-table-column>
            <a-table-column title="主图" :width="150" key="imgUrl" data-index="imgUrl" fixed="left">
              <template slot-scope="scope, record">
                <div>
                  <QiniuUpload
                    :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
                    type="image"
                    :value.sync="record.imgUrl"
                  ></QiniuUpload>
                </div>
              </template>
            </a-table-column>
            <!-- <label > -->
            <a-table-column
              align="center"
              :width="120"
              :ellipsis="true"
              v-for="(spec, sIndex) in formThead"
              :key="sIndex"
              :title="spec"
            >
              <template slot-scope="scope, record">
                {{ record.goodsSpecList && record.goodsSpecList[sIndex].goodsSpecValue.specValue }}
              </template>
            </a-table-column>
            <!-- </label> -->
            <a-table-column key="grossWeight" data-index="grossWeight" title="重量(kg)" :width="150">
              <template slot-scope="scope, record">
                <div>
                  <a-input
                    :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
                    class="xe-input-smal90"
                    v-model="record.grossWeight"
                    @blur="
                      record.grossWeight = parseFloat(record.grossWeight)
                        ? Math.abs(parseFloat(record.grossWeight))
                        : ''
                    "
                  ></a-input>
                </div>
              </template>
            </a-table-column>
            <a-table-column key="" data-index="" title="功能类型" :width="150">
              <template slot-scope="scope, record">
                <a-select
                  :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
                  placeholder="请选择"
                  mode="multiple"
                  v-model="record.ids"
                >
                  <a-select-option :value="item.id" v-for="(item, index) in functionType" :key="index">{{
                    item.name
                  }}</a-select-option>
                </a-select>
              </template>
            </a-table-column>
            <a-table-column key="price" data-index="price" title="市场价" :width="150">
              <template slot-scope="scope, record">
                <div>
                  <a-input-number
                    :formatter="(value) => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                    :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
                    :min="1"
                    :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
                    class="xe-input-smal90"
                    v-model="record.price"
                    @blur="record.price = parseFloat(record.price) ? Math.abs(parseFloat(record.price)).toFixed(2) : ''"
                  ></a-input-number>
                </div>
              </template>
            </a-table-column>
            <a-table-column key="sellPrice" data-index="sellPrice" title="标准开票价（D价）" algin="right" :width="150">
              <template slot-scope="scope, record">
                <div>
                  <a-input-number
                    :formatter="(value) => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                    :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
                    :min="1"
                    :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
                    class="xe-input-smal90"
                    v-model="record.sellPrice"
                    @blur="
                      record.sellPrice = parseFloat(record.sellPrice)
                        ? Math.abs(parseFloat(record.sellPrice)).toFixed(2)
                        : ''
                    "
                  ></a-input-number>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="实际库存" key="realQuantity" data-index="realQuantity" :width="150">
              <template slot-scope="scope, record">
                <div>
                  <a-input
                    :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
                    class="xe-input-smal90"
                    :maxlength="8"
                    v-model="record.realQuantity"
                    @blur="
                      record.realQuantity =
                        parseInt($event.target.value) > 0 ? (record.realQuantity = parseInt($event.target.value)) : ''
                    "
                  ></a-input>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="销售库存" key="saleQuantity" data-index="saleQuantity" :width="150">
              <template slot-scope="scope, record">
                <div>
                  <a-input
                    :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
                    class="xe-input-smal90"
                    :maxlength="8"
                    v-model="record.saleQuantity"
                    @blur="
                      record.saleQuantity =
                        parseInt($event.target.value) > 0 ? (record.saleQuantity = parseInt($event.target.value)) : ''
                    "
                  ></a-input>
                </div>
              </template>
            </a-table-column>
            <!-- <a-table-column
              title="图号"
              key="chartCount"
              data-index="chartCount"
              :width="150">
              <template slot-scope="scope, record">
                <div>
                  <a-input :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'" class="xe-input-smal90" :maxlength="8" v-model="record.chartCount"></a-input>
                </div>
              </template>
          </a-table-column> -->
            <a-table-column
              :render-header="renderHeader"
              title="物料编码"
              key="goodsCode"
              data-index="goodsCode"
              :width="150"
            >
              <template slot-scope="scope, record">
                <div>
                  <a-input
                    :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
                    @blur="proNumBlur($event, record)"
                    v-model="record.goodsCode"
                  ></a-input>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="商家编码" key="sellerCode" data-index="sellerCode" :width="150">
              <template slot-scope="scope, record">
                <div>
                  <a-input
                    :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
                    class="xe-input-smal90"
                    :maxlength="8"
                    v-model="record.sellerCode"
                  ></a-input>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="条形码" key="barCode" data-index="barCode" :width="150">
              <template slot-scope="scope, record">
                <div>
                  <a-input
                    :disabled="handle == 'check' || handle == 'cusCheck' || handle == 'look'"
                    class="xe-input-smal90"
                    v-model="record.barCode"
                  ></a-input>
                </div>
              </template>
            </a-table-column>
          </a-table>
        </div>
      </div>
    </a-form-model>
    <div class="form-title">详细介绍</div>
    <a-form-model-item title="商品描述">
      <Tinymce :value.sync="rowData.content" :disabled="handle == 'check' && handle == 'cusCheck'"></Tinymce>
    </a-form-model-item>
    <div class="footer-bts" v-if="handle != 'check' && handle != 'cusCheck' && handle != 'look'">
      <a-button key="back" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="toSubmit">提交</a-button>
    </div>
  </a-modal>
</template>
<script>
import { editProductGoods, selectByIdProductGoods, addProductGoods, doExchange } from '../api/ProductGoodsApi'
import $ from 'jquery'
import Tinymce from '../../../components/Editor/Tinymce'

export default {
  created() {},
  components: {
    Tinymce,
  },
  data() {
    return {
      url: '',
      brandList: [],
      // 规格
      typeSpecList: [],
      selectSkuInfo: {},
      typeSpecTableData: [],
      typeSpecArr: [],
      loadingtable: false,
      specNewVal: '',
      specOldVal: '',
      specList: [],
      proValueList: {},
      infoSpecName: 'default',
      parentIds: [],
      categoryList: [],
      content: '',
      loading: false,
      masterloading: false,
      masterImageUrl: '',
      imageUrl: '',
      value: '',
      visible: false,
      handle: 'add',
      rowData: {},
      fileList: [],
      options: [],
      propertyList: [],
      categoryId: 0,
      specvalueId: '',
      brandId: [],
      categoryIds: [],
      data: [],
      proValue: {},
      flagAutoUp:true,
      sort:'',
      // 表单验证
      formRule: {
        name: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        barCode: [{ required: true, message: '请输入条形码', trigger: 'blur' }],
        saleQuantity: [{ required: true, message: '请输入销售库存', trigger: 'blur' }],
        ids: [{ required: true, message: '请选择功能类型', trigger: 'change' }],
        proUnit: [{ required: true, message: '请选择单位', trigger: 'change' }],
        // sort: [{ required: true, pattern: new RegExp(/^[0-9]+$/), message: '请输入排序', trigger: 'blur' }],
      },
      nuits: [],
      ditchList: [],
      functionType: [],
    }
  },

  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      const that = this
      that.rowData = {}
      that.typeSpecTableData = []
      that.typeSpecList = []
      that.categoryIds = []
      that.propertyList = []
      that.brandId = []
      that.typeSpecArr = []
      that.url = ''
      if (handle == 'add') {
        // that.rowData.flagAutoUp = true
        that.rowData.sort = 1
      } else if (handle == 'edit' || handle == 'check' || handle == 'look') {
        let id = handle == 'edit' ? row.id : handle == 'check' ? row.id : row.productId
        that.axios.get('/api/product/system/info/selectById/' + id).then((res) => {
          that.queryByCategory(row.categoryId, res.body.goodsList, 2, res)
          that.rowData = res.body
          this.flagAutoUp = res.body.flagAutoUp
          this.sort = res.body.sort
          that.brandId = [
            {
              label: that.rowData.brandName,
              key: that.rowData.brandId,
            },
          ]
          let unit = []
          this.propertyList = res.body.propertyList
        })
      } else if (handle == 'cusCheck') {
        that.axios.get('/api/product/system/info/selectById/' + row.productIdByGoodsId).then((res) => {
          that.rowData = res.body
          that.brandId = [
            {
              label: that.rowData.brandName,
              key: that.rowData.brandId,
            },
          ]
          let specList = []
          res.body.propertyList.forEach((item) => {
            let value = {
              name: item.propertyName,
              id: item.productBasePropertyId,
            }
            specList.push(
              Object.assign({}, value, {
                valueObj: [
                  {
                    key: item.propertyValuesList[0].productBasePropertyValueId,
                    label: item.propertyValuesList[0].propertyValue,
                  },
                ],
              })
            )
          })
          that.propertyList = specList
          that.categoryIds = res.body.parentIdOrg.split('_').map(Number)
          that.url = res.body.pictureList
            ? res.body.pictureList
                .map((e) => {
                  return e.url
                })
                .toString()
            : ''
          let unit = []
        })
      }

      // 获取分类列表
      this.axios.get('/api/product/category/categoryList?FlagEnable=true').then((res) => {
        this.categoryList = res.body[0] ? res.body[0].children : []
      })

      // 获取供应商
      this.axios.get('/api/base/system/dictionary/selectByCode/' + '供应商').then((res) => {
        // 获取供应商
        this.ditchList = res.body
      })
      // 获取商品计量单位
      this.axios.get('/api/base/system/dictionary/selectByCode/' + '商品单位').then((res) => {
        // 获取商品计量单位
        this.nuits = res.body
      })

      // 获取功能类型列表
      this.axios.get('/api/product/product/productFunctionType/list?pageSize=99999').then((res) => {
        this.functionType = res.body.records
      })

      this.visible = true
    },
    onProUnitChange(value) {
      this.$refs.test.onFieldChange()
    },
    reShowData(data) {
      if (data.length) {
        let result = {
          typeSpecArr: [],
          specOldVal: [],
        }
        let resultObj = {}

        data.forEach((item) => {
          item.goodsSpecList.forEach((sitem) => {
            let str = `${sitem.specId}卍${sitem.specName}卍${sitem.goodsSpecValue.specValue}`
            if (!resultObj[str]) {
              resultObj[str] = 1
              result.typeSpecArr.push(str)
            }
          })
        })
        this.typeSpecArr = result.typeSpecArr
      }
    },

    queryByCategory(id, list, num, orgSource) {
      this.axios.get('/api/product/spec/queryByCategoryId/' + id).then((res) => {
        this.typeSpecList = res.body
        if (num == 2) {
          if (this.typeSpecList.length <= 0) {
            let data = list
            data.forEach((item, index) => {
              item.goodsSpecList = [
                {
                  specName: '--规格--',
                  specValue: '无',
                },
              ]
            })
            this.typeSpecTableData = data
          } else {
            this.typeSpecTableData = list
            this.reShowData(list)
          }
        }
      })
      this.axios.get('/api/product/product/baseProperty/queryByCategoryId/' + id).then((res) => {
        this.propertyList = res.body
        let list = []
        if (this.propertyList != null && this.propertyList.length > 0) {
          this.propertyList.map((item) => {
            list.push(Object.assign({}, item, { valueObj: {} }))
          })

          let specList = []
          // let tmpList =
          //   this.rowData.propertyList && this.rowData.propertyList.length > 0 ? this.rowData.propertyList : []
          this.propertyList.forEach((item) => {
            this.rowData.propertyList.forEach((e) => {
              if (item.id == e.productBasePropertyId) {
                let value = {
                  name: e.propertyName,
                  id: e.productBasePropertyId,
                  type: e.type,
                  valueObj: [
                    {
                      key: e.propertyValuesList[0].productBasePropertyValueId,
                      label: e.propertyValuesList[0].propertyValue,
                    },
                  ],
                }
                if (e.type == 1) {
                  specList.push(
                    Object.assign({}, value, {
                      propertyValueList: item.propertyValueList,
                    })
                  )
                } else if (e.type == 0) {
                  specList.push(
                    Object.assign({}, value, {
                      propertyValueList: [
                        {
                          id: e.propertyValuesList[0].productBasePropertyValueId,
                          name: e.propertyValuesList[0].propertyValue,
                        },
                      ],
                    })
                  )
                }
              }
            })
          })
          this.propertyList = specList
          // console.log(this.propertyList, 2222)
          this.categoryIds = orgSource.body.parentIdOrg.split('_').map(Number)
          this.url = orgSource.body.pictureList
            ? orgSource.body.pictureList
                .map((e) => {
                  return e.url
                })
                .toString()
            : ''
        }else {
          this.propertyList = []
        }
      })
      // 获取品牌列表
      this.axios.get('/api/product/system/brand/getProductName/' + id).then((res) => {
        this.brandList = res.body
      })
    },

    onchange(value) {
      this.infoSpecName = 'edit'
    },
    // 禁用弹框
    handleCancel() {
      this.$refs.form.resetFields();
      this.visible = false
    },

    /**
     * 表单提交
     */
    toSubmit() {
      console.log(this.propertyList)
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        let list = []
        this.propertyList.forEach((item, index) => {
          let value = {}
          if (item.type == 1) {
            if (this.infoSpecName == 'default') {
              value.productBasePropertyValueId = item.valueObj[0].key
              value.propertyValue = item.valueObj[0].label
            } else if (this.infoSpecName == 'edit') {
              value.productBasePropertyValueId = item.valueObj.key
              value.propertyValue = item.valueObj.label
            }
          } else if (item.type == 0) {
            value.productBasePropertyValueId = item.propertyValueList[0].id
            value.propertyValue = item.propertyValueList[0].name
          }
          list.push({
            productBasePropertyId: item.id,
            propertyName: item.name,
            valueObj: value,
            propertyValuesList: [],
          })
        })
        list.forEach((data) => {
          data.propertyValuesList.push(data.valueObj)
        })
        this.rowData.pictureList = this.url.split(',').map((item) => {
          return {
            url: item,
          }
        })
        this.rowData.propertyList = list
        this.rowData.goodsList = this.typeSpecTableData
        this.rowData.goodsSpecList = []
        this.rowData.goodsSpecList = this.typeSpecTableData.goodsSpecList
        this.rowData.categoryId = Number(this.categoryIds.slice(-1).toString())
        if(this.rowData.goodsList.length == 0){
          return this.$notification.warning({message: '商品规格不能为空'} )
        }
        this.rowData.goodsList.forEach((item) => {
          item.specStr = item.goodsSpecList ? item.goodsSpecList.map((x) => x.goodsSpecValue.specValue).join('卍') : ''
        })
        if (!this.rowData.categoryId) {
          this.$message.warning('请选择分类')
          return
        }
        if (!this.rowData.brandId) {
          this.$message.warning('请选择品牌')
          return
        }
        if (!this.rowData.imgUrl) {
          this.$message.warning('请上传商品主图')
          return
        }
        if (this.rowData.pictureList.length <= 0) {
          this.$message.warning('请上传商品图片')
          return
        }
        this.rowData.flagAutoUp = this.flagAutoUp
        this.rowData.sort = this.sort
        const res = this.handle == 'add' ? await addProductGoods(this.rowData) : await editProductGoods(this.rowData)
        if (res.code == 200) {
          this.$notification.success({ message: res.message })
          this.rowData = {}
          this.typeSpecTableData = []
          this.typeSpecList = []
          this.categoryIds = []
          this.brandId = []
          this.propertyList = []
          this.typeSpecArr = []
          this.url = ''
          this.$emit('reload')
          this.visible = false
        } else {
          this.visible = false
          this.$notification.error({ message: res.message })
        }
      })
    },

    onBrandChange(obj, e) {
      this.$refs.test.onFieldChange()
      this.rowData.brandName = obj.label
      this.rowData.brandId = obj.key
    },
    onTagChange(value) {},
    onChange(value, e) {
      var reg = new RegExp(',', 'g')
      this.rowData.parentCategoryName = e
        .map((i) => {
          return i.name
        })
        .toString()
        .replace(reg, '_')
      this.rowData.parentIdOrg = e
        .map((i) => {
          return i.id
        })
        .toString()
        .replace(reg, '_')
      this.rowData.categoryName = e.pop().name
      let item = Number(value.slice(-1).toString())
      this.$refs.test.onFieldChange()
      this.queryByCategory(item, [], 1, [])
    },

    onCatChange(value) {
      this.$refs.test.onFieldChange()
    },

    proNumBlur(event, rowData) {
      // 验证货号是否重复
      // const value = event.target.value;
      // if (!event.target.value) return;
      // this.axios.get('/api/product/system/goods/isExistGoodsCode?goodsCode=' + value).then(res => {
      //   if(res == true) {
      //     return this.$message.warning('物料编号不能重复')
      //   }
      // })
    },

    typeSpecfn(event, specName, specId, specValue, id) {
      // 选择规格值
      // [['200-颜色-红', '200-颜色-黄'], ['201-形状-正方形', '201-形状-长方形'], ['202-味道-甜']]
      this.specvalueId = id
      let dom = $(event.target || event.srcElement)
      let strs = `${specId}卍${specName}卍${specValue}`
      if (dom.hasClass('active')) {
        let index = this.typeSpecArr.indexOf(strs)
        this.typeSpecArr.splice(index, 1)
        this.$nextTick(() => {
          if (this.specNewVal == undefined) {
            // 删除至最后一个时清空数据
            this.typeSpecTableData = []
            return
          }
          if (this.specNewVal[0].indexOf(specId + '卍') < 0) {
            this.typeSpecTableData.forEach((item, index) => {
              item.goodsSpecList.some((sitem, sindex) => {
                if (sitem.specId == specId) {
                  item.goodsSpecList.splice(sindex, 1)
                  return true
                }
              })
            })
          } else {
            let tempArray1 = []
            let tempArray2 = []
            let indexArr = []
            this.specNewVal.some((newitem) => {
              //tempArray1[newitem] = true;
              tempArray1.push(newitem)
            })
            this.typeSpecTableData.forEach((item, index) => {
              let reArr = []
              item.goodsSpecList.forEach(function (sitem) {
                reArr.push(`${sitem.specId}卍${sitem.specName}卍${sitem.goodsSpecValue.specValue}`)
              })
              let resultStr = reArr.join(',')
              let obj = {}
              obj[index] = resultStr
              tempArray2.push(obj)
            })
            tempArray2.forEach((item, index) => {
              let tempArray11 = []
              let tempArray21 = []
              tempArray1.forEach((item, index) => {
                item.split(',').forEach((item, index) => {
                  tempArray11.push(item)
                })
              })

              tempArray21 = item[index].split(',')
              let flag = false
              tempArray21.forEach((item, index) => {
                if (tempArray11.indexOf(item) == -1) {
                  flag = true
                }
              })
              if (flag) {
                indexArr.push(index)
              }
              // if (!tempArray1[item[index]]) {
              //     indexArr.push(index);
              // }
            })
            // 删除操作
            let copyTableData = [...this.typeSpecTableData]
            indexArr.forEach((item) => {
              copyTableData.splice(item, 1, undefined)
            })
            this.typeSpecTableData = copyTableData.filter((item) => {
              return item !== undefined
            })
          }
        })
      } else {
        this.typeSpecArr.push(strs)
        if (this.typeSpecTableData.length > 0) {
          this.$nextTick(() => {
            if (this.specOldVal[0].indexOf(specId + '卍') >= 0) {
              let tempArray1 = []
              let tempArray2 = []
              this.specOldVal.forEach((item) => {
                tempArray1[item] = true
              })
              this.specNewVal.forEach((item) => {
                if (!tempArray1[item]) {
                  tempArray2.push(item)
                }
              })
              tempArray2.forEach((item) => {
                let obj = {
                  imgUrl: '',
                  goodsSpecList: [
                    {
                      specId: '',
                      specName: '',
                      goodsSpecValue: {
                        specValue: '',
                        specValueId: '',
                      },
                    },
                  ],
                  goodsCode: '', // 货号
                  grossWeight: '', // 重量
                  costPrice: '', // 预计成本价
                  sellPrice: '', // 建议标准开票价（D价）
                  marketPrice: '', //市场价
                  realQuantity: '', // 真实库存
                  saleQuantity: '', // 销售库存
                  minQuantity: '', // 起订量
                  maxQuantity: '', //限购量
                  barCode: '', // 条形码
                  sellerCode: '', // 商家编码
                  chartCount: '', // 图号
                  oeCode: '', // OE码
                  flagRebate: '', // 是否折扣
                  flagVolkswagen: '', // 是否为大众产品
                  name: this.rowData.name,
                }
                let itemarr = item.split(',')
                let itemResultArr = []
                let firProSkuSpecList = this.typeSpecTableData[0].goodsSpecList
                firProSkuSpecList.forEach((fItem) => {
                  itemarr.forEach((sItem) => {
                    if (sItem.split('卍')[0] == fItem.specId) {
                      let list = sItem.split('卍')
                      itemResultArr.push({
                        specId: list[0],
                        specName: list[1],
                        specValue: list[2],
                        goodsSpecValue: {
                          specValue: list[2],
                          specValueId: id,
                        },
                      })
                    }
                  })
                })
                obj.goodsSpecList = itemResultArr
                this.typeSpecTableData.push(obj)
              })
            } else {
              this.typeSpecTableData.forEach((item) => {
                let list = strs.split('卍')
                item.goodsSpecList.push({
                  specId: list[0],
                  specName: list[1],
                  specValue: list[2],
                  goodsSpecValue: {
                    specValue: list[2],
                    specValueId: id,
                  },
                })
                item.goodsSpecList = item.goodsSpecList.sort(function (a, b) {
                  return a.specId + '卍' + b.specId
                })
              })
            }
          })
        }
      }
      if (!this.typeSpecTableData.length) {
        this.typeSpecTableData = this.typeSpecChooseResult
      }
    },

    extendTypeChange(val) {
      // 根据ID查询扩展属性
      if (val == this.editorTypeId) {
        // 编辑时
        this.queryVenProSkuList(val)
      } else {
        this.queryProTypeInfo(val)
      }
    },
    picRemove(index) {
      this.uploadImg2.splice(index, 1)
      this.picindex = ''
      $('.inputFile2').val('')
    },
    pricingTypeChange(val) {
      this.queryFareBaseList()
    },
    addBrandfn() {
      this.$refs.popbrand_Instance.submitForm((brandInf) => {
        this.addBrand = false
        this.brandIdList.push(brandInf)
        this.filterForm.brandId = brandInf.brandId
      })
    },
    addnewAttributeFn() {
      this.$refs.popbox_Instance.Preservation(() => {
        this.queryProTypeList()
        this.addnewAttribute = false
      })
    },
    queryBrandList(brandId) {
      // 查询品牌列表数据
      this.$http({
        method: 'post',
        url: '/ven/proSpu/queryBrandList',
        data: {},
      })
        .then((res) => {
          this.brandIdList = res.data.proBrandList
        })
        .catch((error) => {})
    },
    queryProTypeList() {
      this.$http({
        method: 'post',
        url: '/ven/proSpu/queryProTypeList',
      })
        .then((res) => {
          this.extendSelects = res.data.proTypeList
          if (this.filterForm.typeId == '') {
            this.filterForm.typeId = this.extendSelects[0].typeId
          }
          this.queryProTypeInfo(this.filterForm.typeId)
        })
        .catch((error) => {})
    },
    queryProTypeInfo(id) {
      this.$http({
        method: 'post',
        url: '/ven/proSpu/queryProTypeInfo',
        data: {
          typeId: id,
        },
      })
        .then((res) => {
          this.typeSpecArr = []
          this.typeSpecTableData = [] // 清除商品规格table
          this.typePropertyListComputed = res.data.typeInfoMap.typePropertyList
          this.typeSpecList = res.data.typeInfoMap.typeSpecList
          if (!this.typeSpecList.length) {
            let obj = {
              imgUrl: '',
              goodsSpecList: [
                {
                  specName: '--规格--',
                  specValue: '无',
                },
              ],
              goodsCode: '', // 货号
              grossWeight: '', // 重量
              costPrice: '', // 预计成本价
              sellPrice: '', // 建议标准开票价（D价）
              marketPrice: '', //市场价
              realQuantity: '', // 真实库存
              saleQuantity: '', // 销售库存
              minQuantity: '', // 起订量
              maxQuantity: '', //限购量
              barCode: '', // 条形码
              sellerCode: '', // 商家编码
              chartCount: '', // 图号
              oeCode: '', // OE码
              flagRebate: '', // 是否折扣
              flagVolkswagen: '', // 是否为大众产品
              name: this.rowData.name,
            }
            this.typeSpecTableData.push(obj)
          }
        })
        .catch((error) => {})
    },

    renderHeader(h, { column, $index }) {
      return (
        <div>
          <span style={{ color: 'red' }}>*</span> {column.label}
        </div>
      )
    },
  },
  watch: {
    typeSpecChoose(newVal, oldVal) {
      this.specNewVal = doExchange(newVal)
      this.specOldVal = doExchange(oldVal)
    },
  },

  computed: {
    typeSpecChoose() {
      let hash = {}
      let result = []
      this.typeSpecArr.forEach((item, index) => {
        if (hash[item.split('卍')[0]]) {
          hash[item.split('卍')[0]].push(item)
        } else {
          hash[item.split('卍')[0]] = []
          hash[item.split('卍')[0]].push(item)
        }
      })
      for (let key in hash) {
        result.push(hash[key])
      }
      return result
    },
    typeSpecChooseResult() {
      let result = doExchange(this.typeSpecChoose)
      let resultArrs = []
      if (this.typeSpecChoose.length) {
        result.forEach((item, index) => {
          let obj = {
            imgUrl: '',
            goodsSpecList: [],
            goodsCode: '', // 货号
            grossWeight: '', // 重量
            costPrice: '', // 预计成本价
            sellPrice: '', // 建议标准开票价（D价）
            marketPrice: '', //市场价
            realQuantity: '', // 真实库存
            saleQuantity: '', // 销售库存
            minQuantity: '', // 起订量
            maxQuantity: '', //限购量
            barCode: '', // 条形码
            sellerCode: '', // 商家编码
            chartCount: '', // 图号
            oeCode: '', // OE码
            flagRebate: '', // 是否折扣
            flagVolkswagen: '', // 是否为大众产品
            name: this.rowData.name,
          }
          let itemarr = item.split(',')
          let itemResultArr = []
          itemarr.forEach((sItem) => {
            let list = sItem.split('卍')
            itemResultArr.push({
              specId: list[0],
              specName: list[1],
              specValue: list[2],
              goodsSpecValue: {
                specValue: list[2],
                specValueId: this.specvalueId,
              },
            })
          })
          obj.goodsSpecList = itemResultArr
          resultArrs.push(obj)
        })
      }
      return resultArrs
    },
    formThead() {
      return (
        this.typeSpecTableData[0].goodsSpecList &&
        this.typeSpecTableData[0].goodsSpecList.map((v) => {
          return v.specName
        })
      )
    },
  },
}
</script>

<style lang="less" scoped>
.product-form {
  .required_fields:before {
    content: '*';
    color: red;
    margin-right: 4px;
    margin-bottom: -28px;
    margin-left: 63px;
  }
  .required_fields1:before {
    content: '*';
    color: red;
    margin-right: 4px;
    margin-bottom: -28px;
    margin-left: 88px;
  }
  .footer-bts {
    text-align: right;
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #e9e9e9;
  }
  .form-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 15px;

    span {
      color: red;
    }
  }

  .inline {
    display: flex;
    justify-content: space-around;
  }

  .form-label,
  .upload-hint-text {
    line-height: 25px;
  }

  .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
  }

  .upload-list-inline .ant-upload-animate-enter {
    animation-name: uploadAnimateInlineIn;
  }

  .upload-list-inline .ant-upload-animate-leave {
    animation-name: uploadAnimateInlineOut;
  }

  .buju {
    .ant-col-3 {
      width: 15% !important;
    }

    .ant-form-item-control-wrapper {
      width: 85% !important;
    }
  }

  .ant-form-item {
    margin-bottom: 13px;
  }

  .pub-tabDatadetails {
    position: relative;
    border-left: 1px solid #ced5de;
    border-bottom: 1px solid #ced5de;
    border-top-left-radius: 3px;

    &:after {
      position: absolute;
      content: '';
      right: 0;
      bottom: 0;
      top: 30px;
      border-right: 1px solid #ced5de;
    }

    .el-tabs--card > .el-tabs__header .el-tabs__item {
      //border:1px solid #ced5de;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      background: #f3f5f8;
      color: #7b8693;
      border: 1px solid #ced5de;

      &:first-child {
        border-left: none;
      }

      &.is-active {
        color: #20a0ff;
        background: #fff;
        border-bottom: 1px solid transparent;
      }
    }

    .el-tabs__item {
      height: 30px;
      line-height: 30px;
      padding: 0 16px;
      color: #7b8693;
      margin-right: 5px;
    }

    .el-tabs__content {
      padding: 5px 20px 20px;
    }

    .detail_picbox {
      padding: 10px 0;

      img {
        display: block;
        width: 60px;
        height: 60px;
      }
    }
  }

  .rseGds-content {
    margin-bottom: 25px;
    padding-top: 0;
    //width: 1101px;
    .el-dialog__footer {
      border-top: 1px solid #dce2ea;
      padding: 15px 20px;
    }

    .el-form-item {
      margin-bottom: 15px !important;
    }
  }

  .for-itemlis {
    padding-bottom: 12px;

    .item-head {
      height: 44px;
      line-height: 44px;
      border-bottom: 1px solid #e9e9e9;
      margin: 0 0 15px;

      .item-tit {
        font-size: 14px;
        margin-right: 15px;
      }
    }

    .item-content {
      .listColumn {
        line-height: 20px;
        color: #464c5b;
        margin: 5px 20px;
      }
    }

    .addnewtype {
      font-size: 12px;
      color: #525d6e;
      margin-left: 10px;
      cursor: pointer;

      &.no-margin {
        margin: 3px 0 0 -5px;
      }
    }

    .item-tabbox {
      padding: 0 20px 0px;

      .ditor-01 {
        width: 90%;
        margin-bottom: 20px;

        .typ_lis {
          display: inline-block;
          padding: 4px 20px;
          background: #f5f5f5;
          border: 1px solid #dfe5ec;
          margin: 5px 8px 5px 0px;
          border-radius: 4px;

          &.active {
            border-color: #20a0ff;
            color: #20a0ff;
          }
        }
      }

      .skuImgUpload {
        position: relative;
        background: #f5f6fa;
        display: inline-block;
        width: 60px;
        height: 60px;
        margin: 10px 0;

        img {
          width: 100%;
          height: 100%;
        }

        .skuImgFile {
          width: 100%;
          height: 100%;
          font-size: 100px;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          z-index: 3;
        }

        span {
          width: 30px;
          height: 30px;
          font-size: 24px;
          line-height: 30px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          color: #8c939d;
          margin: -15px 0 0 -15px;
          z-index: 2;
        }

        .showImg {
          width: 60px;
          height: 60px;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
          background-color: #fff;
          border: 1px solid #c2ccd1;

          img {
            width: 100%;
            height: 100%;
          }

          em {
            width: 18px;
            height: 18px;
            font-size: 18px;
            color: #ff0000;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            right: -9px;
            top: -9px;
          }
        }
      }
    }

    /*商品图片*/

    .item-uploadImg_box {
      padding: 4px 22px;

      ul {
        float: left;

        li {
          position: relative;
          width: 60px;
          height: 60px;
          border: 1px solid #d9d9d9;
          border-radius: 2px;
          float: left;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
          }

          p {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: rgba(0, 0, 0, 0.4);
            font-size: 14px;
            text-align: center;
            line-height: 58px;
            color: #fff;
          }
        }
      }

      .uploadImg-btn {
        float: left;
        width: 60px;
        height: 60px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        background: #fbfdff;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        .inputFile2 {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 100px;
          z-index: 3;
          opacity: 0;
        }

        span {
          display: inline-block;
          width: 30px;
          height: 30px;
          font-size: 24px;
          text-align: center;
          line-height: 30px;
          color: #8c939d;
          position: absolute;
          left: 50%;
          top: 50%;
          margin: -15px 0 0 -15px;
        }
      }
    }

    /*富文本编辑器*/

    .text-editor-box {
      margin: 0 20px;
    }

    .form_sub_btn {
      margin: 28px 20px 0;
    }
  }
}
</style>
