/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-06-09 17:38:36
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-11 19:02:28
 */
import { axios } from '@/utils/request'

export const listProductGoods = params => axios({
    url: '/api/product/system/goods/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addProductGoods = params => axios({
    url: '/api/product/system/info/dealer/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editProductGoods = params => axios({
    url: '/api/product/system/info/dealer/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delProductGoods = params => axios({
    url:'/api/product/system/info/dealer/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const rollbackStatus = params => axios({
  url:'/api/product/system/info/rollbackStatus/' + params,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const selectByIdProductGoods = params => axios({
    url: '/api/product/system/dealer/info/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const listInfoGoods = params => axios({
  url: '/api/product/system/dealer/info/list',
  method: 'get',
  params,
  headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const repositoryList = params => axios({
  url: '/api/product/system/goods/dealer/repository/list',
  method: 'get',
  params,
  headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export function doExchange(arrays) {
  var len = arrays.length;
  if (len >= 2) {
    var len1 = arrays[0].length;
    var len2 = arrays[1].length;
    var newlen = len1 * len2;
    var temp = new Array(newlen);
    var index = 0;
    for (let i = 0; i < len1; i++) {
      for (let j = 0; j < len2; j++) {
        temp[index] = arrays[0][i] + ',' + arrays[1][j];
        index++;
      }
    }
    var newArray = new Array(len - 1);
    for (let i = 2; i < len; i++) {
      newArray[i - 1] = arrays[i];
    }
    newArray[0] = temp;
    return doExchange(newArray);
  } else {
    return arrays[0];
  }
}